exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-coin-details-tsx": () => import("./../../../src/pages/coinDetails.tsx" /* webpackChunkName: "component---src-pages-coin-details-tsx" */),
  "component---src-pages-coin-tsx": () => import("./../../../src/pages/coin.tsx" /* webpackChunkName: "component---src-pages-coin-tsx" */),
  "component---src-pages-consulting-tsx": () => import("./../../../src/pages/consulting.tsx" /* webpackChunkName: "component---src-pages-consulting-tsx" */),
  "component---src-pages-create-dao-tsx": () => import("./../../../src/pages/createDao.tsx" /* webpackChunkName: "component---src-pages-create-dao-tsx" */),
  "component---src-pages-create-nft-tsx": () => import("./../../../src/pages/createNFT.tsx" /* webpackChunkName: "component---src-pages-create-nft-tsx" */),
  "component---src-pages-create-organization-tsx": () => import("./../../../src/pages/createOrganization.tsx" /* webpackChunkName: "component---src-pages-create-organization-tsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-learn-details-tsx": () => import("./../../../src/pages/learnDetails.tsx" /* webpackChunkName: "component---src-pages-learn-details-tsx" */),
  "component---src-pages-learn-tsx": () => import("./../../../src/pages/learn.tsx" /* webpackChunkName: "component---src-pages-learn-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-market-tsx": () => import("./../../../src/pages/market.tsx" /* webpackChunkName: "component---src-pages-market-tsx" */),
  "component---src-pages-nft-collections-tsx": () => import("./../../../src/pages/NFTCollections.tsx" /* webpackChunkName: "component---src-pages-nft-collections-tsx" */),
  "component---src-pages-nft-creators-details-tsx": () => import("./../../../src/pages/NFTCreatorsDetails.tsx" /* webpackChunkName: "component---src-pages-nft-creators-details-tsx" */),
  "component---src-pages-nft-creators-tsx": () => import("./../../../src/pages/NFTCreators.tsx" /* webpackChunkName: "component---src-pages-nft-creators-tsx" */),
  "component---src-pages-nft-details-tsx": () => import("./../../../src/pages/NFTDetails.tsx" /* webpackChunkName: "component---src-pages-nft-details-tsx" */),
  "component---src-pages-nft-featured-tsx": () => import("./../../../src/pages/NFTFeatured.tsx" /* webpackChunkName: "component---src-pages-nft-featured-tsx" */),
  "component---src-pages-nft-market-tsx": () => import("./../../../src/pages/NFTMarket.tsx" /* webpackChunkName: "component---src-pages-nft-market-tsx" */),
  "component---src-pages-projects-details-tsx": () => import("./../../../src/pages/projectsDetails.tsx" /* webpackChunkName: "component---src-pages-projects-details-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-send-tsx": () => import("./../../../src/pages/send.tsx" /* webpackChunkName: "component---src-pages-send-tsx" */),
  "component---src-pages-sign-tsx": () => import("./../../../src/pages/sign.tsx" /* webpackChunkName: "component---src-pages-sign-tsx" */),
  "component---src-pages-user-tsx": () => import("./../../../src/pages/user.tsx" /* webpackChunkName: "component---src-pages-user-tsx" */)
}

